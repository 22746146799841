import "./AboutSection.css";

import ScrollAnimation from "../ScrollAnimation/ScrollAnimation";

const AboutSection = ({ locale }) => {
    return (
        <div className="aboutSection" id="about">
            <ScrollAnimation style={"toRight"} classname="left">
                <h2>{locale === "sk" ? "O nás" : "About us"}</h2>
                <p>
                    {locale === "sk"
                        ? "Výsadou Bossa je pokora, slušnosť a úcta. Takto sa aj my chceme správať k naším Bossom. Naše jedlo sme zo streetfoodovej sféry chceli povýšiť na zážitkové gastro v príjemnom prostredí, kde Vás radi uvidíme."
                        : "The privilege of the Boss is humility, decency, and respect. This is how we want to behave to our Bosses. We wanted to elevate our food from the street food sphere to experiential gastro in a pleasant environment, where we are happy to see you."}
                    <br></br>
                    <br></br>
                    {locale === "sk"
                        ? "Boss Burger nie je len burger. Našou filozofiou je socializovať ľudí a navrátiť do reštaurácii život. Práve preto neponúkame rozvoz jedla."
                        : "Boss Burger is not just a burger. Our philosophy is to socialize people and bring life back to restaurants. That's why we don't offer food delivery."}
                    <br></br>
                    <br></br>
                    {locale === "sk"
                        ? "Sme veľmi radi, že tu sedíte a veríme, že Vám pripravíme chuťový zážitok, ktorý vo Vás zanechá príjemnú emóciu."
                        : "We are very glad that you are sitting here and we believe that we will prepare a taste experience for you that will leave a pleasant emotion in you."}
                </p>
            </ScrollAnimation>
            <ScrollAnimation style={"toLeft"} classname="right">
                <h2>{locale === "sk" ? "OTVÁRACIE HODINY" : "OPENING HOURS"}</h2>
                <div className="top">
                    <p>
                        <span>{locale === "sk" ? "Po-Št:" : "Mon-Thu:"}</span>10:00-22:00
                    </p>
                    <p>
                        <span>{locale === "sk" ? "Piatok:" : "Friday:"}</span>10:00-24:00
                    </p>
                    <p>
                        <span>{locale === "sk" ? "Sobota:" : "Saturday:"}</span>14:00-24:00
                    </p>
                    <p>
                        <span>{locale === "sk" ? "Nedeľa:" : "Sunday:"}</span>14:00-22:00
                    </p>
                </div>
                <div className="bottom">
                    <h2>{locale === "sk" ? "ADRESA" : "ADDRESS"}</h2>
                    <p>Jurkovičová 18</p>
                    <p>080 01 Prešov</p>
                </div>
            </ScrollAnimation>
        </div>
    );
};

export default AboutSection;
