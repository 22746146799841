import { Routes, Route, useLocation } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import HomePage from './pages/HomePage/HomePage'
import MenuPage from './pages/MenuPage/MenuPage'
import Rezervacie from './pages/Rezervacie/Rezervacie'
import Map from './components/Map/Map'
import Footer from './components/Footer/Footer'

import { useEffect } from 'react'

import HomeAboutSection from './components/HomeAboutSection/HomeAboutSection'

import Pdf from './components/PDF/Pdf'
import { useState } from 'react'

const Layout = ({ children, locale, setLocale }) => (
    <>
        <Navbar locale={locale} setLocale={setLocale} />
        {children}
        <HomeAboutSection locale={locale} />
        <Map locale={locale} />
        <Footer locale={locale} />
    </>
)

const App = () => {
    const [locale, setLocale] = useState('sk')

    const location = useLocation()
    useEffect(() => {
        const mouseMove = (e) => {
            const cursor_dot = document.querySelector('.cursor_dot')
            const cursor_outline = document.querySelector('.cursor_outline')

            const posX = e.clientX
            const posY = e.clientY

            cursor_dot.style.left = posX + 'px'
            cursor_dot.style.top = posY + 'px'

            cursor_outline.animate(
                {
                    left: posX + 'px',
                    top: posY + 'px'
                },
                {
                    duration: 1000,
                    fill: 'forwards'
                }
            )
        }
        window.addEventListener('mousemove', mouseMove)

        return () => {
            window.removeEventListener('mousemove', mouseMove)
        }
    }, [])

    useEffect(() => {
        const cursor_dot = document.querySelector('.cursor_dot')
        const cursor_outline = document.querySelector('.cursor_outline')

        cursor_dot.classList.remove('cursor_dot_hover')
        cursor_outline.classList.remove('cursor_outline_hover')

        function cursor_hover() {
            const cursor_dot = document.querySelector('.cursor_dot')
            const cursor_outline = document.querySelector('.cursor_outline')

            cursor_dot.classList.add('cursor_dot_hover')
            cursor_outline.classList.add('cursor_outline_hover')
        }
        function cursor_stop_hover() {
            const cursor_dot = document.querySelector('.cursor_dot')
            const cursor_outline = document.querySelector('.cursor_outline')

            cursor_dot.classList.remove('cursor_dot_hover')
            cursor_outline.classList.remove('cursor_outline_hover')
        }

        function nastavenie_hover_na_elementy() {
            const vsetky_hover_elementy = document.querySelectorAll(
                '[data-hover="hover"]'
            )

            vsetky_hover_elementy.forEach((element) => {
                element.onmouseover = cursor_hover
                element.onmouseout = cursor_stop_hover
            })
        }

        window.addEventListener('resize', nastavenie_hover_na_elementy)

        nastavenie_hover_na_elementy()
    }, [location])

    return (
        <>
            <Routes>
                <Route
                    path='/'
                    element={
                        <Layout locale={locale} setLocale={setLocale}>
                            <HomePage locale={locale} />
                        </Layout>
                    }
                />
                <Route
                    path='/menu'
                    element={
                        <Layout locale={locale} setLocale={setLocale}>
                            <MenuPage locale={locale} />
                        </Layout>
                    }
                />
                <Route
                    path='/reservation'
                    element={
                        <Layout locale={locale} setLocale={setLocale}>
                            <Rezervacie locale={locale} />
                        </Layout>
                    }
                />
                <Route path='/menu/pdf' element={<Pdf />} />
            </Routes>
            <div className='cursor_dot'></div>
            <div className='cursor_outline'></div>
        </>
    )
}

export default App
