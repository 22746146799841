import TopSection from '../../components/TopSection/TopSection'
import HomeMenu from '../../components/HomeMenu/HomeMenu'
import AboutSection from '../../components/AboutSection/AboutSection'
import { Helmet } from 'react-helmet'

const HomePage = ({ locale }) => {
    return (
        <>
            <Helmet>
                <title>Boss Burger Prešov</title>
            </Helmet>
            <TopSection />
            <HomeMenu locale={locale} />
            <AboutSection locale={locale} />
        </>
    )
}

export default HomePage
