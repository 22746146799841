import { useRef, useEffect, useState } from 'react'
import { useInView } from 'framer-motion'

const ScrollAnimation = ({ children, style, classname }) => {
    const ref = useRef(null)
    const isInView = useInView(ref, { once: true })
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleWindowResize = () => setViewportWidth(window.innerWidth)
        window.addEventListener('resize', handleWindowResize)
        return () => window.removeEventListener('resize', handleWindowResize)
    }, [])

    const transformValue = '30px'

    let styling

    if (style === 'toRight') {
        styling = {
            transform: isInView ? 'none' : `translateX(-${transformValue})`,
            opacity: isInView ? 1 : 0,
            transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s'
        }
    }
    if (style === 'toLeft') {
        styling = {
            transform: isInView ? 'none' : `translateX(${transformValue})`,
            opacity: isInView ? 1 : 0,
            transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s'
        }
    }
    if (style === 'up') {
        styling = {
            transform: isInView ? 'none' : `translateY(${transformValue})`,
            opacity: isInView ? 1 : 0,
            transition: 'all 0.5s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s'
        }
    }

    return (
        <div className={classname} ref={ref} style={styling}>
            {children}
        </div>
    )
}

export default ScrollAnimation
