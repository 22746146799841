import "./HomeAboutSection.css";

import logo from "../../img/logo_resized.png";
import { HashLink } from "react-router-hash-link";

const HomeAboutSection = ({ locale }) => {
    return (
        <div className="homeAboutSection">
            <img src={logo} alt="bossburger logo" />
            <h2>{locale === "sk" ? "Boss Burger nájdete na Jurkovičovej 18 v Prešove" : "You can find Boss Burger at Jurkovičová 18 in Prešov"}</h2>
            <HashLink to="/#contact" data-hover="hover">
                {locale === "sk" ? "KONTAKT" : "CONTACT"}
            </HashLink>
        </div>
    );
};

export default HomeAboutSection;
