import './Rezervacie.css'
import LoadedImage from '../../components/LoadedImage/LoadedImage'

import rezervaciaBG from '../../img/menuImages/12_resized.jpg'
import rezervaciaBG_blur from '../../img/menuImages/12_blur.jpg'
import AnimatedPage from '../../components/AnimatedPage/AnimatedPage'
import { Helmet } from 'react-helmet'

import ScrollAnimation from '../../components/ScrollAnimation/ScrollAnimation'
import { useState } from 'react'

const Rezervacie = ({ locale }) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Helmet>
                <title>
                    {locale === 'sk'
                        ? 'Boss Burger Prešov Rezervácia'
                        : 'Boss Burger Prešov Reservation'}
                </title>
            </Helmet>
            <div className='rezervacie'>
                <div className='rezervacieTop'>
                    <LoadedImage
                        src={[rezervaciaBG]}
                        blur={rezervaciaBG_blur}
                        alt={'al capone burger'}
                    />
                    <AnimatedPage>
                        <h1>
                            {locale === 'sk' ? 'Rezervácia' : 'Reservation'}
                        </h1>
                    </AnimatedPage>
                </div>
                <div className='content'>
                    <ScrollAnimation style={'toRight'} classname='left'>
                        <h2>
                            {locale === 'sk' ? 'Rezervácia' : 'Reservation'}
                        </h2>
                        <div className={'contact'}>
                            <h3>Telefonicky</h3>
                            <a href='tel:+421948990999' data-hover='hover'>
                                <p>+421 948 990 999</p>
                            </a>
                        </div>
                        <div className={'contact'}>
                            <h3>Online</h3>
                            <ScrollAnimation
                                className={`reservationFormWrapper`}
                                style={'up'}
                            >
                                <div className='reservationFormWrapper'>
                                    <iframe
                                        id='bookio-iframe'
                                        src='https://www.bookiopro.com/boss-burger/rs-widget?lang=sk&c1=ffbe0b&c2=ffffff&c3=000000'
                                        width='100%'
                                        onload='initBookioWidget()'
                                        scrolling='no'
                                    ></iframe>
                                </div>
                            </ScrollAnimation>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation style={'toLeft'} classname='right'>
                        <h2>
                            {locale === 'sk'
                                ? 'OTVÁRACIE HODINY'
                                : 'OPENING HOURS'}
                        </h2>
                        <div className='top'>
                            <p>
                                <span>
                                    {locale === 'sk' ? 'Po-Št:' : 'Mon-Thu:'}
                                </span>
                                10:00-22:00
                            </p>
                            <p>
                                <span>
                                    {locale === 'sk' ? 'Piatok:' : 'Friday:'}
                                </span>
                                10:00-24:00
                            </p>
                            <p>
                                <span>
                                    {locale === 'sk' ? 'Sobota:' : 'Saturday:'}
                                </span>
                                14:00-24:00
                            </p>
                            <p>
                                <span>
                                    {locale === 'sk' ? 'Nedeľa:' : 'Sunday:'}
                                </span>
                                14:00-22:00
                            </p>
                        </div>
                        <div className='bottom'>
                            <h2>{locale === 'sk' ? 'ADRESA' : 'ADDRESS'}</h2>
                            <p>Jurkovičová 18</p>
                            <p>080 01 Prešov</p>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
            <script
                type='text/javascript'
                src='https://s3.eu-central-1.amazonaws.com/bookiopro/assets/bookio.widget.js'
            ></script>
        </>
    )
}

export default Rezervacie
