import { HashLink } from "react-router-hash-link";

import { Link } from "react-router-dom";

import ScrollAnimation from "../ScrollAnimation/ScrollAnimation";

import { useEffect } from "react";

import "./HomeMenu.css";

import LoadedImage from "../LoadedImage/LoadedImage";

import bossburger from "../../img/menuImages/10_small.jpg";
import bossburger_blur from "../../img/menuImages/10_blur.jpg";

import menu from "../../img/menuImages/6_small.jpg";
import menu_blur from "../../img/menuImages/6_blur.jpg";

import kontakt from "../../img/menuImages/14_small.jpg";
import kontakt_blur from "../../img/menuImages/14_blur.jpg";

import tower from "../../img/menuImages/8_small.jpg";
import tower_blur from "../../img/menuImages/8_blur.jpg";

import lotus from "../../img/menuImages/3_small.jpg";
import lotus_blur from "../../img/menuImages/3_blur.jpg";

import godfather from "../../img/menuImages/2_small.jpg";
import godfather_blur from "../../img/menuImages/2_blur.jpg";

const HomeMenu = ({locale}) => {
    return (
        <div className="homeMenu">
            <ScrollAnimation style={"toRight"} classname="bossburger">
                <LoadedImage src={[bossburger]} blur={bossburger_blur} alt={"doctor lucky burger"} />
                <p>#bossburger</p>
                <h2>{locale === "sk" ? "Domáce burgre" : "Homemade burgers"}</h2>
                <p>{locale === "sk" ? "Jedlo - Drinky - Zábava" : "Food - Drinks - Fun"}</p>
            </ScrollAnimation>
            <ScrollAnimation style={"toLeft"} classname="menu">
                <LoadedImage src={[menu]} blur={menu_blur} alt={"spicy vito burger"} />

                <h2>Menu</h2>
                <p>{locale === "sk" ? "Po-Ne" : "Mo-Su"}</p>
                <Link to="/menu" reloadDocument data-hover="hover">
                    Menu
                </Link>
            </ScrollAnimation>
            <ScrollAnimation style={"toLeft"} classname="kontakt">
                <LoadedImage src={[kontakt]} blur={kontakt_blur} alt={"roast beef burger"} />

                <h2>{locale === "sk" ? "Kontakt" : "Contact"}</h2>
                <HashLink to="/#contact" data-hover="hover">
                    {locale === "sk" ? "KONTAKT" : "CONTACT"}
                </HashLink>
            </ScrollAnimation>
            <HashLink to="/menu#tower" className="tower" data-hover="hover">
                <ScrollAnimation style={"up"}>
                    <LoadedImage src={[tower]} blur={tower_blur} alt={"tower burger"} />
                    <h2>Tower</h2>
                </ScrollAnimation>
            </HashLink>
            <HashLink to="/menu#lotus" className="lotus" data-hover="hover">
                <ScrollAnimation style={"up"}>
                    <LoadedImage src={[lotus]} blur={lotus_blur} alt={"lotus burger"} />
                    <h2>Lotus</h2>
                </ScrollAnimation>
            </HashLink>
            <HashLink data-hover="hover" to="/menu#godfather" className="godfather">
                <ScrollAnimation style={"up"}>
                    <LoadedImage src={[godfather]} blur={godfather_blur} alt={"godfather burger"} />
                    <h2>Godfather</h2>
                </ScrollAnimation>
            </HashLink>
        </div>
    );
};

export default HomeMenu;
