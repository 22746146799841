import React from "react";
import { useState } from "react";



const LoadedImage = (props) => {
    const [loaded, setLoaded] = useState(false);

    const HandleLoad = () => {
        setLoaded(true);
    };

    const divStyle = loaded ? {} : { backgroundImage: `url(${props.blur})` };

    let alt
    try {
        alt = props.alt.toLowerCase()

    } catch {

    }

    return (
        <div className="blurLoad" style={divStyle}>
            <img alt={alt} loading="lazy" src={props.src} className={`${loaded ? "loaded" : ""}`} onLoad={HandleLoad} />
        </div>
    );
};

export default LoadedImage;
