import "./TopSection.css"
import logo from "../../img/logo_resized.png"

import homeBG from "../../img/menuImages/9_resized.jpg"
import homeBG_blur from "../../img/menuImages/9_blur.jpg"

import LoadedImage from "../LoadedImage/LoadedImage"
import AnimatedPage from "../AnimatedPage/AnimatedPage"

const TopSection = () => {
    return (
        <div className="topSection">
            <LoadedImage src={[homeBG]} blur={homeBG_blur} alt={"royal crown burger"} />
            <AnimatedPage>
                <img src={logo} alt="boss burger logo" className="logo" />
            </AnimatedPage>
        </div>
    )
}

export default TopSection