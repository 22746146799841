import "./Navbar.css";
import { Link } from "react-router-dom";
import Logo from "../../img/logo_resized.png";
import { HashLink } from "react-router-hash-link";
import { NavLink } from "react-router-dom";

import MediaQuery from "react-responsive";
import { useEffect, useState } from "react";

const Navbar = ({ locale, setLocale }) => {
    const [menu, setMenu] = useState(false);

    useEffect(() => {
        try {
            if (menu !== false) {
                const menuIcon = document.querySelector(".menuIcon");
                menuIcon.classList.add("active");
                menuIcon.classList.remove("notActive");

                const mobileMenu = document.querySelector(".mobile");
                mobileMenu.classList.add("active");
            } else {
                const menuIcon = document.querySelector(".menuIcon");
                menuIcon.classList.remove("active");
                menuIcon.classList.add("notActive");

                const mobileMenu = document.querySelector(".mobile");
                mobileMenu.classList.remove("active");
            }
        } catch {}
    }, [menu]);

    return (
        <>
            <MediaQuery maxWidth={800}>
                <nav className="mobileNav" id="top">
                    <div className="upperNav">
                        <div className="contact">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                </svg>
                                <Link data-hover="hover" to="https://www.google.com/maps?q=Jurkovičova+18,+08001+Prešov" target="_blank">
                                    <p>Jurkovičova 18, 08001 Prešov</p>
                                </Link>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                                </svg>
                                <Link data-hover="hover" to="tel:0948990999">
                                    <p>0948 990 999</p>
                                </Link>
                            </div>
                        </div>
                        <div className="social">
                            <Link data-hover="hover" to="https://www.facebook.com/bossburgerpresov/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                                </svg>
                            </Link>
                            <Link data-hover="hover" to="https://www.instagram.com/bossburgerpresov/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="bottomNav">
                        <Link to="/" data-hover="hover">
                            <img src={Logo} alt="bossburger logo" />
                        </Link>
                        <div
                            className="changeLanguage"
                            onClick={() => {
                                if (locale === "sk") {
                                    setLocale("en");
                                } else {
                                    setLocale("sk");
                                }
                            }}
                        >
                            <p style={{ color: locale === "sk" ? "grey" : "white" }}>EN</p>
                            <p>/</p>
                            <p style={{ color: locale === "en" ? "grey" : "white" }}>SK</p>
                        </div>
                        <div data-hover="hover" className="menuIcon notActive" onClick={() => setMenu(!menu)}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="mobile">
                        <ul>
                            <li>
                                <Link onClick={() => setMenu(!menu)} data-hover="hover" to="/">
                                    <p>{locale === "sk" ? "DOMOV" : "HOME"}</p>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={() => setMenu(!menu)} data-hover="hover" to="/menu">
                                    <p>{locale === "sk" ? "MENU" : "MENU"}</p>
                                </Link>
                            </li>
                            <li>
                                <HashLink onClick={() => setMenu(!menu)} data-hover="hover" to="/#about">
                                    <p>{locale === "sk" ? "O NÁS" : "ABOUT US"}</p>
                                </HashLink>
                            </li>
                            <li>
                                <HashLink onClick={() => setMenu(!menu)} data-hover="hover" to="/#contact">
                                    <p>{locale === "sk" ? "KONTAKT" : "CONTACT"}</p>
                                </HashLink>
                            </li>
                            <li>
                                <Link onClick={() => setMenu(!menu)} data-hover="hover" to="/reservation">
                                    <p>{locale === "sk" ? "REZERVÁCIA" : "RESERVATION"}</p>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </MediaQuery>

            <MediaQuery minWidth={800}>
                <nav className="desktopNav" id="top">
                    <div className="upperNav">
                        <div className="contact">
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                                </svg>
                                <Link data-hover="hover" to="https://www.google.com/maps?q=Jurkovičova+18,+08001+Prešov" target="_blank">
                                    <p>Jurkovičova 18, 08001 Prešov</p>
                                </Link>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                                </svg>
                                <Link data-hover="hover" to="tel:0948990999">
                                    <p>0948 990 999</p>
                                </Link>
                            </div>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
                                </svg>
                                <Link data-hover="hover" to="mailto:boss@bossburger.sk">
                                    <p>boss@bossburger.sk</p>
                                </Link>
                            </div>
                        </div>
                        <div className="social">
                            <Link data-hover="hover" to="https://www.facebook.com/bossburgerpresov" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                                </svg>
                            </Link>
                            <Link data-hover="hover" to="https://www.instagram.com/bossburgerpresov/" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                                </svg>
                            </Link>
                        </div>
                    </div>
                    <div className="bottomNav">
                        <Link to="/" data-hover="hover">
                            <img src={Logo} alt="bossburger logo" />
                        </Link>
                        <ul>
                            <li>
                                <NavLink to="/" data-hover="hover">
                                    <p>{locale === "sk" ? "DOMOV" : "HOME"}</p>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/menu#top" data-hover="hover">
                                    <p>{locale === "sk" ? "MENU" : "MENU"}</p>
                                </NavLink>
                            </li>
                            <li>
                                <HashLink to="/#about" data-hover="hover">
                                    <p>{locale === "sk" ? "O NÁS" : "ABOUT US"}</p>
                                </HashLink>
                            </li>
                            <li>
                                <HashLink to="/#contact" data-hover="hover">
                                    <p>{locale === "sk" ? "KONTAKT" : "CONTACT"}</p>
                                </HashLink>
                            </li>
                            <li>
                                <NavLink to="/reservation#top" data-hover="hover">
                                    <p>{locale === "sk" ? "REZERVÁCIA" : "RESERVATION"}</p>
                                </NavLink>
                            </li>
                            <div
                                className="changeLanguage"
                                onClick={() => {
                                    if (locale === "sk") {
                                        setLocale("en");
                                    } else {
                                        setLocale("sk");
                                    }
                                }}
                            >
                                <p style={{ color: locale === "sk" ? "grey" : "white" }}>EN</p>
                                <p>/</p>
                                <p style={{ color: locale === "en" ? "grey" : "white" }}>SK</p>
                            </div>
                        </ul>
                    </div>
                </nav>
            </MediaQuery>
        </>
    );
};

export default Navbar;
